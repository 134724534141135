import { FC } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import ArrowDown from "../assets/icons/arrowDown.svg?react";
import "../styles/components/menuBtns.css";

export const MenuBtns: FC<{
  active?: { name: string; value: string | number };
  elements: { name: string; value: string | number }[];
  handleChange: (opts: { name: string; value: string | number }) => void;
  placeholder?: string;
}> = ({ active, handleChange, elements, placeholder }) => {
  return (
    <Menu>
      <MenuButton className="menu-btn-selected">
        {active?.name || placeholder || "Не выбрано"} <ArrowDown />
      </MenuButton>
      <MenuItems
        transition={true}
        anchor="bottom end"
        className="origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 menu-btns-items"
      >
        {elements.map((i) => (
          <MenuItem key={i.name + i.value}>
            <button
              onClick={() => handleChange(i)}
              className={`menu-btns-item menu-btns-item-active__${i.value === active?.value}`}
            >
              {i.name}
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};
